const getQueryParams = () => {
  if (typeof window === "undefined") return {}

  const query = window.location.search.substring(1)

  if (query === "") return {}
  let queryArr = query.split("&")

  let mappedQuery = {}
  for (const index in queryArr) {
    const splittedItem = queryArr[index].split("=")

    mappedQuery = { ...mappedQuery, [splittedItem[0]]: splittedItem[1] }
  }

  return mappedQuery
}

export default getQueryParams
