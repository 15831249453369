import DefaultLayout from "../../layout/DefaultLayout"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import { Link } from "gatsby"

// Hooks
import useListing from "@mkt/ui/components/common/Listing/hooks"

// Data
import { incomeOptions } from "@mkt/ui/components/common/Listing/data"

// Components
import Hero from "@mkt/ui/components/Ccbr/Hero"
import Img from "@mkt/ui/components/common/Img"
import Button from "@mkt/ui/components/Ccbr/Button"
import Drawer from "@mkt/ui/components/common/Drawer"
import Accordion from "./components/Accordion"

// Assets
import SearchIcon from "@mkt/ui/icons/Ccbr/png/Search24.png"
import CardIcon from "@mkt/ui/icons/Ccbr/png/Card24.png"
import CloseIcon from "@mkt/ui/icons/Ccbr/png/Close24.png"
import ScoreIcon from "@mkt/ui/icons/Ccbr/png/Score24.png"
import MoneyIcon from "@mkt/ui/icons/Ccbr/png/MoneyBadge24.png"

// Styles
import * as S from "./styles"
import Loading from "@mkt/ui/components/common/Loading"

const Listing = ({ pageContext, location }) => {
  const {
    content,
    loading,
    heroTitle,
    products,
    filters,
    isFilterOpen,
    formFilterRef,
    handleFilterSubmit,
    handleClearFilter,
    handleToggleFilter,
    handleCardClick,
  } = useListing({ pageContext })

  return (
    <DefaultLayout pageContext={pageContext} location={location}>
      <SbEditable content={content}>
        <div css={S.listing}>
          <Hero
            content={content?.hero[0]}
            title={heroTitle}
            type="list"
            breadcrumbs
          />
          <div css={S.listingContainer}>
            <div css={S.listingFilter}>
              <h1>Lista de Cartões</h1>
              <button onClick={handleToggleFilter}>
                <Img src={SearchIcon} alt="Search icon" />
                <span>Filtrar Cartões</span>
              </button>
            </div>
            <SbEditable content={products}>
              <div css={S.listingCards} data-onaccess="productListViewed">
                {loading && <Loading />}
                {!loading &&
                  products?.map((card, index) => {
                    const slug = card.full_slug.replace(
                      "/cartao-de-credito",
                      ""
                    )

                    return (
                      <SbEditable
                        content={card}
                        key={`#listing-ccbr-${card._uid}-${index}`}
                        css={S.listingCardWrapper}
                      >
                        <div
                          css={S.listingCardItem}
                          data-scroll="productViewed"
                          data-position={index}
                        >
                          <Img
                            css={S.cardItemImage}
                            src={card?.image?.filename}
                            alt={card?.image?.alt}
                          />
                          <div css={S.cardItemContent}>
                            <h2>{card?.title}</h2>
                            <div className="content__wrapper">
                              <p>
                                Renda mínima:{" "}
                                <span>{card?.minimum_income}</span>
                              </p>
                              <p>
                                Anuidade: <span>{card?.annuity}</span>
                              </p>
                            </div>
                          </div>
                          <div css={S.cardItemButtonGroup}>
                            <Button
                              expand="x"
                              justify="center"
                              onClick={() => handleCardClick(card, index, slug)}
                            >
                              Peça já!
                            </Button>
                            <Link
                              to={card?.full_slug?.replace(
                                "cartao-de-credito/",
                                ""
                              )}
                            >
                              <Img src={CardIcon} alt="Card Icon" />
                              <span>Mais detalhes</span>
                            </Link>
                          </div>
                        </div>
                      </SbEditable>
                    )
                  })}
              </div>
            </SbEditable>
          </div>
        </div>
        <Drawer isOpen={isFilterOpen} handleToggleDrawer={handleToggleFilter}>
          <div css={S.filterHeader}>
            <a href="#" onClick={handleToggleFilter}>
              <Img src={CloseIcon} alt="Close icon" />
            </a>
          </div>
          <form
            css={S.filterContent}
            onSubmit={handleFilterSubmit}
            ref={formFilterRef}
          >
            <Accordion
              icon={CardIcon}
              title="Banco ou emissor"
              subtitle="Filtre por banco ou emissor"
              field="issuer"
              type="radio"
              options={filters.issuers}
            />
            <Accordion
              icon={ScoreIcon}
              title="Benefícios"
              subtitle="Filtre por tipo de benefício"
              field="category"
              type="radio"
              options={filters.categories}
            />
            <Accordion
              icon={MoneyIcon}
              title="Renda Mínima"
              subtitle="Filtre por renda mínima"
              field="income"
              type="radio"
              options={incomeOptions}
            />
            <div css={S.contentButtonGroup}>
              <Button htmlType="submit">Ver resultados</Button>
              <a href="#" onClick={handleClearFilter}>
                <span>Limpar filtros</span>
              </a>
            </div>
          </form>
        </Drawer>
      </SbEditable>
    </DefaultLayout>
  )
}

export default Listing
