// Hooks
import useAccordion from "./hooks"

// Components
import Img from "@mkt/ui/components/common/Img"

// Styles
import * as S from "./styles"

// Assets
import ArrowIcon from "@mkt/ui/icons/Ccbr/ArrowDown"

const Accordion = ({
  icon,
  title,
  subtitle,
  field,
  type = "checkbox",
  options,
}) => {
  const { isAccordionOpen, handleToggleAccordion } = useAccordion()

  return (
    <div>
      <div
        css={S.accordion}
        className={isAccordionOpen ? "--open" : "--closed"}
        onClick={handleToggleAccordion}
      >
        <Img className="accordion__icon" src={icon} alt="Accordion Icon" />
        <div className="accordion__content">
          <p>{title}</p>
          <span>{subtitle}</span>
        </div>
        <div
          className={`accordion__chevron ${
            isAccordionOpen ? "--open" : "--closed"
          }`}
        >
          <ArrowIcon />
        </div>
      </div>
      <div
      css={S.accordionContent}
      className={isAccordionOpen ? "--open" : "--closed"}
      >
        {options?.map((option) => (
          <div css={S.contentOption} key={`accordion-ccbr-${option.id}`}>
            <input
              type={type}
              id={option.value}
              name={field}
              value={option.value}
            />
            <label htmlFor={option?.value}>{option?.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Accordion
