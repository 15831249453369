import { css, keyframes } from "@emotion/react"
import { fadeIn } from "@mkt/ui/styles/Keyframes"

const rotateIn = keyframes`
  0% {
    opacity: .5;
    transform: rotate(0deg);
  }

  100% {
    opacity: 1;
    transform: rotate(180deg);
  }
`
const rotateOut = keyframes`
  0% {
    transform: rotate(-180deg);
    opacity: .5;
  }

  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
`

export const accordion = css`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 680px;
  border-bottom: 1px solid #dedede;
  padding: 28px 16px;
  cursor: pointer;

  .accordion__icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 24px;
  }

  .accordion__content {
    font-size: 18px;
    line-height: 24px;

    p {
      font-weight: 700;
    }

    span {
      color: #999;
    }
  }

  .accordion__chevron {
    width: 16px;
    margin-left: auto;
    animation: ${rotateOut} 0.42s forwards;

    &.--open {
      animation: ${rotateIn} 0.42s forwards;
    }
  }

  .icon {
    width: 100%;
  }
`

export const accordionContent = css`
  margin-top: -1px;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  max-width: 680px;

  &.--open {
    opacity: 1;
    visibility: visible;
    animation: ${fadeIn} 0.42s forwards;
  }

  &.--closed {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
`

export const contentOption = css`
  position: relative;
  font-size: 16px;
  padding: 14px 68px;
  border-bottom: 1px solid #efefef;

  &:last-of-type {
    border: none;
  }

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &[type="checkbox"] ~ [for]:before,
    &[type="radio"] ~ [for]:before {
      border-radius: 50%;
      background-size: auto !important;
    }

    &[type="checkbox"]:checked ~ [for]:before,
    &[type="radio"]:checked ~ [for]:before {
      background-color: #5473e3;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.44l2.796 2.91L9 0' stroke='%23FFF' fill='none'/%3E%3C/svg%3E");
      border: 1px solid #5473e3;
    }
  }

  label {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &::before {
      content: "";
      background-repeat: no-repeat;
      background-size: 12px 18px;
      display: inline-block;
      width: 24px;
      height: 24px;
      border: 1px solid #656565;
      margin-right: 20px;
      background-position: 50%;
      border-radius: 2px;
    }
  }
`
