import Icon from "@mkt/ui/components/common/Icon";

export default function ArrowDown(props) {
  return (
    <Icon
      iconMarkup={'<svg viewBox="0 0 16 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Group 3</title> <desc>Created with Sketch.</desc> <defs> <polygon id="path-1" points="0 0 16 0 16 9 0 9"></polygon> </defs> <g id="Pag-Produto-[Mobile]" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Listagem-Produto-Mobile-filtro" transform="translate(-364.000000, -138.000000)"> <g id="Group-3" transform="translate(372.000000, 142.500000) rotate(-360.000000) translate(-372.000000, -142.500000) translate(364.000000, 138.000000)"> <mask id="mask-2" fill="white"> <use xlink:href="#path-1"></use> </mask> <g id="Clip-2"></g> <path d="M8,5.89220659 L2.2763794,0.376098293 C1.75629272,-0.125366098 0.912152025,-0.125366098 0.390731789,0.376098293 C0.130688448,0.628116294 0,0.957282663 0,1.28516323 C0,1.61432959 0.130688448,1.94349596 0.390731789,2.19551397 L7.0571762,8.6232588 C7.30655109,8.86370455 7.64660777,9 8,9 C8.35339223,9 8.69344891,8.86370455 8.9428238,8.6232588 L15.6092682,2.19551397 C15.8693116,1.94349596 16,1.61432959 16,1.28516323 C16,0.957282663 15.8693116,0.628116294 15.6092682,0.376098293 C15.087848,-0.125366098 14.2437073,-0.125366098 13.7236206,0.376098293 L8,5.89220659 Z" id="Fill-1" fill="#1C1C1C" mask="url(#mask-2)"></path> </g> </g> </g></svg>'}
			iconName="ArrowDown"
      {...props}
    />
  );
}
